import * as React from "react"
import { Link } from "gatsby"
import {
  header,
  headerTopBar,
  headerTopWrapper,
  headerBottom,
  navActive,
} from "./header.module.css"
import { container } from "./common.module.css"

import logo from "../images/logo.png"

const Header = ({ page }) => {
  return (
    <header className={header}>
      <section className={headerTopWrapper}>
        <div className={container}>
          <div className={headerTopBar}>
            <div>
              <span>
                <span>Kontakt:</span>
                <a href="tel:+44 697 081 814">
                  <i className="fa fa-phone"></i>+48 697 081 814
                </a>
              </span>
              <span>
                <a href="mailto:gjsystemy@gmail.com">
                  <i className="fa fa-envelope"></i>gjsystemy@gmail.com
                </a>
              </span>
            </div>
            {/* <div>
              <span>RODO | Polityka Prywatnosci</span>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className={container}>
          <div className={headerBottom}>
            <img src={logo} alt="Logo" />

            <nav>
              <Link to="/" className={`${page === "home" ? navActive : ""}`}>
                O firmie
              </Link>
              <Link
                to="/realizacje"
                className={`${page === "realizacje" ? navActive : ""}`}
              >
                Realizacje
              </Link>
            </nav>
          </div>
        </div>
      </section>
    </header>
  )
}

export default Header
