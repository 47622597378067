import * as React from "react"

import { footer, footerContent } from "./footer.module.css"
import { container } from "./common.module.css"

const Footer = () => {
  return (
    <footer className={footer}>
      <div className={container}>
        <div className={footerContent}>
          <span>
            <i className="far fa-registered"></i>2021 GJ SYSTEMY. All Rights
            Reserved.
          </span>{" "}
          <span>
            <a href="tel:+44 697 081 814">
              <i className="fas fa-phone"></i>+48 697 081 814
            </a>
            <a href="mailto:gjsystemy@gmail.com" style={{ marginLeft: "20px" }}>
              <i className="far fa-envelope"></i>gjsystemy@gmail.com
            </a>
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
